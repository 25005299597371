// ===========================================================
// HTML / BODY
// ===========================================================

* {
  box-sizing: border-box;
}

html,
body {
  @include media('>=small') {
    height: 100%;
  }
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  min-width: 320px;
  color: $c_font_default;
  font-family: $f_regular;
  font-size: remSize(16);
  line-height: 1.56;
  -webkit-font-smoothing: antialiased;
}

p {
  color: $c_primary_1;
  margin-top: 0;
  margin-bottom: 20px;
}

.text-light {
  color: $c_mgrey_3;
}

.hidden-svg {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

.hidden-dummy {
  display: none;
}
