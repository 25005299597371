.ranking-medals {
  .ranking-image {
    display: inline-block;
    margin: 10px 18px 0 0;

    @include media('<small') {
      margin-bottom: 10px;
    }
  }
}
