.loader-wrapper {
  position: relative;
  width: 100%;
  min-height: calc(#{500px - 35px} - 2.56rem);

  &.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
