// ===========================================================
// COLORS
// ===========================================================
// Primary colors

$c_primary_1: #003c6e;
$c_primary_2: #2166a0;
$c_primary_3: #336386;

//==================================
// Secondary colors

$c_secondary_1: #ccd8e2;
$c_secondary_2: #668aa8;

//==================================
// Grey colors

$c_dgrey_1: #262626;
$c_dgrey_2: #333;
$c_mgrey_1: #939393;
$c_mgrey_2: #858585;
$c_mgrey_3: #b4b4b4;
$c_lgrey_1: #f0f0f0;
$c_lgrey_2: #e5e5e5;
$c_lgrey_3: #e0e0e0;

//==================================
// Font colors

$c_font_default: $c_primary_1;
$c_divider: $c_lgrey_2;
$c_border: $c_lgrey_1;

//==================================
// Element colors

$c_link: $c_primary_3;
$c_link_hover: $c_primary_1;

$c_heading: $c_primary_1;

$c_button: $c_primary_3;
$c_button_hover: $c_primary_1;

$c_select_arrow: $c_secondary_1;
$c_input_font: $c_primary_1;
$c_input_placeholder: $c_mgrey_1;
$c_input_background: #fff;
$c_input_background_focus: #fff;
$c_input_border: $c_primary_3;
$c_input_border_focus: $c_primary_3;
$c_input_group_icon: $c_secondary_2;
$c_label: $c_primary_3;

$c_popup_bg: #000;

$c_alert_border: $c_secondary_1;
$c_alert_text: $c_primary_1;

//==================================
// Template colors

$c_header_bg: #fff;
$c_link_active: $c_primary_1;
$c_link_active_flyout: #fff;
$c_nav_link: $c_primary_3;
$c_footer_bg: $c_dgrey_1;
$c_resp_nav_flyout_bg: $c_lgrey_1;

//==================================
// Validation

$c_error: #e63250;
$c_success: $c_primary_3;

// ===========================================================
// FONTS
// ===========================================================

$f_regular: Arial, sans-serif;
$f_heading: $f_regular;

// ===========================================================
// BOX SHADOW AND BORDER RADIUS
// ===========================================================

$box_shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
$box_shadow_hover: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
$border_radius: 2px;

// ===========================================================
// CONTAINER WIDTH
// ===========================================================

$w_container: 1210;

// ===========================================================
// DIMENSIONS
// ===========================================================

$d_large: 1260px;
$d_medium: 992px;
$d_small: 768px;
$d_extra_small: 480px;

// ===========================================================
// BREAKPOINTS
// ===========================================================

$breakpoints: (
  'large': $d_large,
  'medium': $d_medium,
  'small': $d_small,
  'extra-small': $d_extra_small,
);

// ===========================================================
// TIME
// ===========================================================

$fast: 100ms;
$medium: 200ms;
$slow: 300ms;

// ===========================================================
// PATHS
// ===========================================================

$img-path: '../../../public/images/';
