a,
.link {
  color: $c_link;
  text-decoration: underline;
}

.title_type_medium {
  font-size: remSize(28);
  color: $c_heading;
  font-weight: bold;
  line-height: 1.2;
  text-overflow: ellipsis;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $f_regular;
}

h1 {
  margin-top: 20px;
  color: $c_heading;
  font-size: remSize(48);
  line-height: 1;
  text-align: left;

  span {
    font-weight: normal;
  }

  @include media('<small') {
    margin-bottom: 39px;
    font-size: remSize(36);
  }
}

h2 {
  margin-bottom: 22px;
  color: $c_heading;
  font-size: remSize(42);
  font-weight: normal;
  line-height: 1;

  @include media('<small') {
    font-size: remSize(30);
  }
}

h3 {
  margin-bottom: 0;
  font-size: remSize(16);

  h2 + & {
    margin-top: -11px;
    margin-bottom: 22px;
    color: $c_heading;
    font-weight: normal;
    line-height: 1;
  }
}
