$icon-path: '../../../public/icons/';

@font-face {
  font-family: 'icomoon';
  src: url($icon-path + 'icomoon.eot?uqred6');
  src:
    url($icon-path + 'icomoon.eot?uqred6#iefix') format('embedded-opentype'),
    url($icon-path + 'icomoon.ttf?uqred6') format('truetype'),
    url($icon-path + 'icomoon.woff?uqred6') format('woff'),
    url($icon-path + 'icomoon.svg?uqred6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon,
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-water-qty-200 .path1:before {
  content: '\e905';
  color: rgb(0, 60, 110);
}

.icon-water-qty-200 .path2:before {
  content: '\e906';
  color: rgb(255, 255, 255);
}

.icon-water-qty-200 .path3:before {
  content: '\e907';
  color: rgb(204, 216, 226);
}

.icon-water-qty-500 .path1:before {
  content: '\e908';
  color: rgb(0, 60, 110);
}

.icon-water-qty-500 .path2:before {
  content: '\e909';
  color: rgb(255, 255, 255);
}

.icon-water-qty-500 .path3:before {
  content: '\e90a';
  color: rgb(204, 216, 226);
}

.icon-water-qty-1000 .path1:before {
  content: '\e90b';
  color: rgb(0, 60, 110);
}

.icon-water-qty-1000 .path2:before {
  content: '\e90c';
  color: rgb(0, 60, 110);
}

.icon-water-qty-1000 .path3:before {
  content: '\e90d';
  color: rgb(255, 255, 255);
}

.icon-water-qty-1000 .path4:before {
  content: '\e90e';
  color: rgb(204, 216, 226);
}

.icon-water-qty-1500 .path1:before {
  content: '\e90f';
  color: rgb(0, 60, 110);
}

.icon-water-qty-1500 .path2:before {
  content: '\e910';
  color: rgb(0, 60, 110);
}

.icon-water-qty-1500 .path3:before {
  content: '\e911';
  color: rgb(255, 255, 255);
}

.icon-water-qty-1500 .path4:before {
  content: '\e912';
  color: rgb(204, 216, 226);
}

%icon-facebook,
.icon-facebook:before {
  content: '\e913';
}

%icon-twitter,
.icon-twitter:before {
  content: '\e914';
}

%icon-xing,
.icon-xing:before {
  content: '\e915';
}

%icon-youtube,
.icon-youtube:before {
  content: '\e916';
}

%icon-checked,
.icon-checked:before {
  content: '\e904';
}

%icon-clear,
.icon-clear:before {
  content: '\e900';
}

%icon-arrow-down,
.icon-arrow-down:before {
  content: '\e901';
}

%icon-menu,
.icon-menu:before {
  content: '\e902';
}

%icon-date,
.icon-date:before {
  content: '\e903';
}

%icon-linkedin,
.icon-linkedin:before {
  content: '\e917';
}
