//****** TABLE OF CONTENTS ******//
//
// TYPO3 STYLES
//// TEXTPIC ELEMENTS
//// ALIGNMENT
//// TABLE STYLING
//// OTHERS
//
//*******************************//

/*--------------------------------
TEXTPIC ELEMENTS
--------------------------------*/

.ce-textpic {
  margin-bottom: 30px;
}

.ce-gallery {
  img {
    //max-width: 100%; // not working in IE 10 and older FF versions
    width: 100%;
    height: auto;
  }
}

.ce-intext {
  &.ce-right {
    .ce-gallery {
      margin-bottom: 40px;
      margin-left: 40px;

      @include media('<medium') {
        margin-bottom: 20px;
        margin-left: 20px;
      }
    }
  }

  &.ce-left {
    .ce-gallery {
      margin-bottom: 40px;
      margin-right: 40px;

      @include media('<medium') {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
  }

  &.ce-above {
    .ce-gallery {
      margin-bottom: 40px;

      @include media('<medium') {
        margin-bottom: 20px;
      }
    }
  }

  &.ce-textpic {
    .ce-column {
      max-width: 700px;

      img {
        width: 100%;
      }
    }
  }

  @include media('<large') {
    &.ce-textpic {
      .ce-column {
        max-width: 400px;
      }
    }
  }

  @include media('<medium') {
    &.ce-textpic {
      .ce-column {
        max-width: 300px;
      }
    }
  }

  @include media('<small') {
    &.ce-textpic {
      .ce-column {
        max-width: none;
        text-align: center;

        img {
          display: inline-block;
        }
      }
    }

    .ce-column {
      float: none;
    }

    .ce-media,
    figure {
      display: inline-block;
    }

    &.ce-right {
      .ce-gallery {
        float: none;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    &.ce-left {
      .ce-gallery {
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.ce-gallery {
  figcaption {
    display: block;
    margin: 15px 0;
    color: $c_mgrey_1;
    font-size: remSize(14);
    text-align: left;

    @include media('<small') {
      font-size: remSize(12);
    }
  }
}

/*--------------------------------
ALIGNMENT
--------------------------------*/

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-justify {
  text-align: justify;
}

/*--------------------------------
TABLE STYLING
--------------------------------*/

table.contenttable {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 0 10px 0 0;
  }

  thead {
    th {
      color: $c_font_default;
      font: {
        weight: normal;
      }
      text-align: left;
    }
  }
}

/*--------------------------------
OTHERS
--------------------------------*/

div.indent {
  margin-left: 2em;
}

span.small-text {
  font-size: 0.8em;
}

.list-unordered {
  margin-top: 0;
}
