// ===========================================================
// GENERAL STYLING
// ===========================================================

button,
input[type='submit'] {
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
}

.btn {
  &:active {
    box-shadow: none;
  }
}

.blueButton {
  display: inline-block;
  position: relative;
  min-width: 148px;
  height: 48px;
  padding: 17px 50px 15px;
  background: $c_button;
  color: #fff;
  line-height: 1;
  font-family: $f_regular;
  font-size: remSize(16);
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all $slow;

  &:hover {
    color: #fff;
    background-color: $c_button_hover;
  }
}

a.blueButton {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    color: #fff;
    text-decoration: none;
  }
}
