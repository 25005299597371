.content-wrap {
  padding-bottom: 0;
  border-top: 1px solid #fff;
}

.container-fluid {
  max-width: #{$w_container}px;
  margin-right: auto;
  margin-left: auto;
}
