.table-style {
  .table-head,
  caption {
    padding: 0 10px 20px;
    border-bottom: 2px solid $c_mgrey_2;
    color: $c_primary_3;
    line-height: 1;
  }

  .table-entry,
  tr {
    padding: 22px 10px;
    border-bottom: 1px solid $c_divider;
  }

  .table-subtitle {
    color: $c_mgrey_3;
  }

  a {
    text-decoration: none;
  }
}

table.table-style {
  width: 100%;

  tr {
    td {
      padding: 22px 0;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }
}

.archive-table,
.ranking-table {
  margin-bottom: 60px;
}
