// ===========================================================
// FORM
// ===========================================================

.form-wrap {
  max-width: 384px;
  margin: 0 auto;
}

.form-small {
  margin-bottom: 60px;
}

// ===========================================================
// INPUTS & LABELS
// ===========================================================

.form-group {
  position: relative;

  @media only screen and (min-width: 768px) {
    label + input[type='text'],
    .form-control {
      min-height: 67px;
      border-top: 27px solid transparent;
    }
  }

  @media only screen and (max-width: 768px) {
    .form-inline input[type='text'].form-control {
      display: inline-block;
      width: auto;
    }
  }

  label + .form-inline {
    border-top: none;
  }

  label + input[type='text']:first-of-type,
  label + input[type='email'],
  label + input[type='date'],
  label + input[type='url'],
  label + input[type='tel'],
  label + input[type='password'],
  label + input[type='number'],
  label + select,
  label + div,
  .form-control:first-of-type {
    min-height: 67px;
    border-top: 27px solid transparent;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 0;
  }

  .input-group label,
  .checkboxWr label {
    // Keep label as static for checkboxes
    position: static;
  }

  .input-group.input-group-water-qty {
    border-top: 27px solid transparent;
  }
}

label {
  margin: 0;
  color: $c_label;
  font-weight: normal;

  .radioWr &,
  .checkboxWr & {
    color: $c_input_font;
    font-size: remSize(16);
  }
}
button[keyboard-focus] {
  outline: 2px solid $c_input_border_focus;
}
input[type='text'],
input[type='email'],
input[type='date'],
input[type='url'],
input[type='tel'],
input[type='password'],
input[type='number'],
select,
.form-control,
.input-group-addon {
  width: 100%;
  max-width: 384px;
  height: 40px;
  padding: 10px 8px 9px;
  background-color: $c_input_background;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 2px solid $c_input_border;
  color: $c_input_font;
  font-size: remSize(16);
  border-radius: 0;
  @include placeholder($c_input_placeholder);
  box-shadow: none;

  &[keyboard-focus] {
    outline: 2px solid $c_input_border_focus;
  }
  &:focus {
    &:not([keyboard-focus]) {
      outline: none;
    }
    border-bottom-color: $c_input_border_focus;
    box-shadow: none;

    & + .input-group-addon {
      border-bottom-color: $c_input_border_focus;
    }
  }
}

.select-container {
  position: relative;
}
select {
  height: 40px;
  appearance: none;
}
select + svg {
  position: absolute;
  // This is not the right way to do it but pseudo elements do not show up for some reason
  transform: translateY(8px) translateX(-120%);
  color: $c_primary_3;
}

.input-group-addon {
  width: auto;
  padding: 8px 10px 5px;
  border: 0;
  border-bottom: 2px solid $c_input_border;
  transition: border-color 0.15s ease-in-out;
  cursor: pointer;

  [class^='icon-'] {
    color: $c_input_group_icon;
    font-size: remSize(24);
  }
}

.input-group.date {
  width: 100%;
  max-width: 384px;

  .input-group-addon {
    width: 40px;
  }
}

.form-group .row {
  max-width: 394px;
  margin-left: -5px;
  margin-right: -5px;

  div[class^='col-'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Radio buttons and checkboxes

input[type='radio'],
input[type='checkbox'] {
  display: block;
  position: absolute;
  top: -2px;
  left: 4px;
  &[keyboard-focus] {
    outline: 2px solid $c_input_border_focus;
  }
}

.radio-inline,
.checkbox-inline {
  padding-right: 0;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  @include media('<small') {
    display: block;
    margin-right: 0;
  }
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  margin-left: 0;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 0;
}

.checkboxWr,
.radioWr {
  position: relative;
  //margin-bottom: 30px;
  padding-top: 0;
  padding-left: 0;
  @include clearfix;

  &:has(input[keyboard-focus]) {
    outline: 2px solid $c_input_border_focus;
  }
  &:has(input:focus:not([keyboard-focus])) {
    outline: none;
  }
  label {
    display: block;
    padding-left: 40px;
    float: none;

    a {
      display: inline-block;
      color: $c_primary_1;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.input-group {
  .radioWr,
  .checkboxWr {
    margin-top: 10px;
  }
}

.fieldLabel {
  display: block;
  color: $c_primary_3;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: remSize(14);
  line-height: 1.6;

  label {
    display: inline;
  }
}

// Radio buttons

.radioWr {
  input[type='radio'] {
    opacity: 0.001;
    position: absolute;
    left: 0;

    & + label {
      cursor: pointer;

      .radio {
        display: inline-block;
        position: absolute;
        left: 0;
        margin: 0;

        &:before,
        &:after {
          display: inline-block;
          content: ' ';
          border-radius: 50%;
        }

        &:before {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          background: $c_input_background;
          border: 2px solid $c_input_border;
        }

        &:after {
          opacity: 0;
          position: absolute;
          top: 8px;
          left: 8px;
          width: 8px;
          height: 8px;
          background: $c_primary_1;
          transition: all 0.3s;
        }
      }
    }

    &:checked + label {
      .radio {
        &:after {
          opacity: 1;
        }
      }
    }

    &:disabled + label {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .validationErrorMessage {
    clear: both;
  }
}

// Checkboxes

.checkboxWr {
  input[type='checkbox'] {
    opacity: 0.001;

    & + label {
      cursor: pointer;

      &:before,
      &:after {
        display: inline-block;
      }

      &:before {
        position: absolute;
        left: 0;
        content: ' ';
        width: 24px;
        height: 24px;
        margin-right: 16px;
        background: $c_input_background;
        border: 2px solid $c_input_border !important;
      }

      &:after {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 1px;
        transition: all 0.3s;
        font-size: 23px;
        @extend %icon;
        @extend %icon-checked;
      }
    }

    &:checked + label {
      &:after {
        opacity: 1;
      }
    }

    &:disabled + label {
      opacity: 0.5;
    }
  }

  .validationErrorMessage {
    clear: both;
  }
}

// Form validation errors

.validationError {
  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='url'],
  input[type='tel'],
  input[type='password'],
  input[type='number'],
  select,
  textarea,
  .input-group-addon,
  .bootstrap-select > .dropdown-toggle.btn-default {
    border-bottom: 2px solid $c_error;

    &:focus {
      border-bottom-color: $c_error;

      & + .input-group-addon {
        border-bottom-color: $c_error;
      }
    }
  }

  .bootstrap-select.open.form-control > .dropdown-toggle.btn-default {
    border-bottom-color: $c_error;
  }

  .fieldLabel,
  .validationErrorMessage {
    color: $c_error;
  }

  .validationErrorMessage {
    font-size: 0.75em;
    line-height: 1.333;
    margin-top: 8px;
    max-width: 384px;
    white-space: pre-line; // Allow line breaks
  }

  .radioWr,
  .checkboxWr {
    label {
      color: $c_error;
    }
  }
}

.error-message {
  display: none;

  .validationError & {
    display: block;
  }
}

// ===========================================================
// ALERTS
// ===========================================================

.alert {
  margin-bottom: 30px;
  padding: 9px 0 10px 14px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: remSize(14);
  line-height: 1.42;

  &.alert-danger {
    border-left: 2px solid lighten($c_error, 20%);
    color: darken($c_error, 7%);
  }

  &.alert-info {
    border-left: 2px solid $c_alert_border;
    color: $c_alert_text;
  }

  &.alert-success {
    border-left: 2px solid lighten($c_success, 20%);
    color: darken($c_success, 7%);
  }
}

// ===========================================================
// DATEPICKER
// ===========================================================

.datepicker {
  &.dropdown-menu {
    padding: 10px 15px;
    background: $c_input_background;
    border-radius: 0;
    border: 0;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
    }
  }

  th,
  td {
    border-radius: 0;
    color: $c_input_font;
    line-height: 1;
  }

  table tr td.old,
  table tr td.new {
    color: $c_label;
  }

  .table-condensed > thead > tr > th,
  .table-condensed > tbody > tr > th,
  .table-condensed > tfoot > tr > th,
  .table-condensed > thead > tr > td,
  .table-condensed > tbody > tr > td,
  .table-condensed > tfoot > tr > td {
    padding: 7px;
  }

  table tr td.day:hover,
  table tr td.day.focused {
    background-color: $c_lgrey_1;
  }

  table tr td.active,
  table tr td.active:hover,
  table tr td.active.disabled,
  table tr td.active.disabled:hover,
  table tr td span.active,
  table tr td span.active:hover,
  table tr td span.active.disabled,
  table tr td span.active.disabled:hover {
    background: $c_primary_1 !important;
    color: #fff;
  }

  table tr td span {
    border-radius: 0;
  }
}

// ===========================================================
// BOOTSTRAP SELECT
// ===========================================================

.bootstrap-select {
  position: relative;

  & > .dropdown-toggle {
    &.btn {
      position: relative;
      height: 40px;
      padding-top: 10px;
      padding-bottom: 9px;
      padding-right: 35px;
      background: $c_input_background;
      color: $c_input_font;
      font-size: remSize(16);
      font-weight: normal;
      text-transform: none;
    }

    &.btn-default {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid $c_input_border;
    }

    &.bs-placeholder,
    &.bs-placeholder:hover,
    &.bs-placeholder:focus,
    &.bs-placeholder:active {
      color: $c_input_font;
    }
  }

  &.open {
    &.form-control {
      background: $c_input_background;

      & > .dropdown-toggle {
        &.btn {
          background: $c_input_background;
        }

        &.btn-default {
          border-bottom-color: $c_input_border_focus;
        }
      }
    }
  }

  .dropdown-toggle {
    &:focus {
      outline: none !important;
    }

    .caret {
      &:after {
        @extend %icon;
        @extend %icon-arrow-down;
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 7px 0;
        color: $c_select_arrow;
        font-size: remSize(26);
        text-align: center;
        vertical-align: bottom;
        pointer-events: none;
      }
    }
  }

  &.btn-group {
    .dropdown-toggle {
      .caret {
        top: 0;
        right: 0;
        margin-top: 0;
        border: 0;
      }
    }
  }

  .dropdown-menu {
    max-height: 200px;
    overflow: auto;
    margin-top: 10px;
    padding: 0;
    background: $c_input_background;
    border: 1px solid $c_lgrey_1;
    border-radius: 0;
    box-shadow: none;

    @include media('<small') {
      max-width: 100%;
    }

    & > li {
      margin-bottom: 0;

      &.disabled {
        opacity: 0.5;
      }

      &.selected > a {
        background-color: darken($c_input_background, 10%);
      }

      & > a {
        padding: 10px 12px;
        color: $c_input_font;

        &:focus,
        &:hover {
          background-color: darken($c_input_background, 10%);
          outline: none;
        }
      }

      &:before {
        display: none;
      }
    }
  }

  &.dropup {
    .dropdown-menu {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

// ===========================================================
// SPECIAL FORM STYLES
// ===========================================================

.button-row {
  margin-top: 84px;
  text-align: center;
}

.input-link-inline {
  position: relative;

  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='url'],
  input[type='tel'],
  input[type='password'],
  input[type='number'] {
    border-top: none !important;
    padding-right: 100px;
  }

  .link-inline {
    position: absolute;
    top: 12px;
    right: 8px;
    font-size: remSize(12);
    text-decoration: none;
  }
}

.form-group {
  margin-bottom: 30px;
}

.form-legend {
  margin-bottom: 30px;
  color: $c_font_default;
  font-size: remSize(28);
  font-weight: bold;
}

.form-group-margin {
  margin-bottom: 60px;
}

.field-closed,
.field-open {
  transition: all 0.3s;
}

.field-closed {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

.field-open {
  display: block;
  opacity: 1;
  max-height: 500px;
  overflow: visible;
  margin-bottom: 30px;
}

.note-required {
  margin-top: 30px;
  font-size: remSize(12);
}
