.section__wrap {
  position: relative;
  min-height: 690px;
  //overflow: hidden;
  background: linear-gradient(to bottom, #f6f6f6 0%, #fefefe 7%, #fff 100%);

  @include media('<small') {
    min-height: 500px;
  }

  &.with-image {
    background-repeat: no-repeat;
    background-size: 1116px auto;
    background-position: center 0;

    @include media('<small') {
      background-size: 528px auto;
      background-position: left 0;
    }

    h1 {
      margin-bottom: 110px;

      @include media('<small') {
        margin-top: 129px;
        margin-bottom: 39px;
      }
    }

    &.landing-page {
      min-height: 0;
      margin-bottom: -80px;
      background-size: cover;
      background-position: 0 0;

      @include media('>=2000px') {
        background-position: 0 30%;
      }

      @include media('<medium') {
        background-position: right 0;
        background-size: contain;

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          z-index: 0;
          top: 0;
          width: 100%;
          height: 0;
          padding-bottom: 48.5%;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, #fff 100%);
        }
      }

      h1 {
        margin-bottom: 46px;

        p {
          margin-bottom: 0;
        }

        @include media('<small') {
          margin-bottom: 39px;
        }
      }
    }

    .page-project & {
      background-size: contain;
      background-position: right bottom;

      @include media('<small') {
        background-position: right top;
      }

      &:before {
        display: block;
        content: '';
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, #f6f6f6 0%, rgba(254, 254, 254, 0) 7%, rgba(255, 255, 255, 0) 100%);
      }

      h1 {
        margin-bottom: 30px;
      }

      h2 {
        margin-bottom: 60px;
      }
    }
  }
}

.section_theme_white {
  padding-top: 0;
}
