.breadcrumbs {
  position: relative;
  z-index: 1;
}

.breadcrumbs__list {
  margin-top: 35px;
  padding-left: 0;
}

.breadcrumbs__item {
  display: inline-block;
  vertical-align: middle;
  padding-left: 4px;
  color: $c_primary_3;

  &:first-child {
    padding-left: 0;
  }

  &:not(:first-child)::before {
    content: '>';
    padding-right: 4px;
  }
}

.breadcrumbs__link {
  text-decoration: none;
}
