.team-drinking-table {
  .table-entry {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .col-team-member-name {
    padding-top: 13px;
    padding-bottom: 13px;

    @include media('>=small') {
      padding-right: 0;
    }
  }

  .col-team-drinking-progress {
    @include media('>=small') {
      padding-left: 0;
    }
  }

  .drinking-progress.team-drinking-progress {
    .drinking-progress-label {
      margin-right: 20px;
    }
  }
}

.team-icon {
  width: 166px;
  display: inline-block;
}
