.header {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;

  a.selected {
    color: $c_primary_1;

    &:after {
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 3px;
      content: '';
      background-color: $c_primary_1;
    }
  }
}

.logo {
  display: block;
  float: left;

  .logo-with-claim, .logo-without-claim {
    // Height including the padding, needed so the navbar stays the same height as with the old logo
    height: 60px;
    // The new logo is a bit bigger, so we add some padding to make it visually the same size as the old one
    padding: 8px 0;
  }

  .logo-with-claim {
    display: block;
  }

  .logo-without-claim {
    display: none;
  }

  @include media('<extra-small') {
    .logo-with-claim {
      display: none;
    }

    .logo-without-claim {
      display: block;
    }
  }
}
