.footer-menu__link {
  color: $c_primary_3;
  transition: all 0.3s;

  &:hover {
    color: $c_primary_1;
  }
}

.pre-footer {
  margin-top: 80px;
  padding: 50px 0;
  background: #f2f5f8;

  @include media('<medium') {
    [class^='col-'] {
      margin-bottom: 30px;
    }
  }
}

.pre-footer__title {
  font-size: 21px;
  font-weight: normal;
  margin: 0 0 25px;
}

.pre-footer__line {
  margin-bottom: 20px;
}

.pre-footer__label {
  font-size: 14px;

  display: block;

  color: $c_primary_3;
}

.pre-footer__social {
  width: 160px;
  margin: 0;
  padding: 0;

  @include media('<medium') {
    width: 100%;
  }
}

.pre-footer__social-item {
  display: inline-block;

  margin-right: 5px;
  margin-bottom: 8px;

  vertical-align: top;
}

.pre-footer__social-link {
  display: block;
  width: 40px;
  height: 40px;
  background: #668aa8;
  color: #fff;
  @extend %icon;
  font-size: 40px;
  text-decoration: none;
  border-radius: 50%;
  transition: all 0.3s;

  &:hover {
    background: $c_primary_1;
    color: #fff;
    text-decoration: none;
  }
}

.pre-footer__social-link_facebook {
  &:before {
    @extend %icon-facebook;
  }
}

.pre-footer__social-link_twitter {
  &:before {
    @extend %icon-twitter;
  }
}

.pre-footer__social-link_youtube {
  &:before {
    @extend %icon-youtube;
  }
}

.pre-footer__social-link_xing {
  &:before {
    @extend %icon-xing;
  }
}

.pre-footer__social-link_linkedin {
  font-size: 28px;
  padding-left: 6px;
  padding-top: 4px;
  &:before {
    @extend %icon-linkedin;
  }
}

.footer {
  padding-top: 50px;
  padding-bottom: 60px;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.copyright {
  display: inline-block;
  vertical-align: middle;
  color: $c_mgrey_1;
}

.footer-menu {
  margin: 0 0 50px;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.footer-menu__link {
  text-decoration: none;
}
