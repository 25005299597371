.diagram-container {
  /*@include media('<small') {
		overflow-x: scroll;

		.section__diagram__line {
			width: 1000px;
		}
	}*/
}

.box_diagram {
  .title_type_medium {
    white-space: normal;
  }
}
