.landing {
  position: relative;
  z-index: 1;
  width: 38.6%;
  margin-bottom: 124px;

  @include media('<large') {
    width: 48%;
  }

  @include media('<medium') {
    width: 55%;
    margin-bottom: 70px;
  }

  @include media('<small') {
    width: 60%;
  }

  @include media('<extra-small') {
    width: 100%;
  }
}

.landing__title {
  margin-top: 201px;
  color: #003c6e;
  font-size: remSize(60);

  @include media('<large') {
    margin-top: 100px;
  }

  @include media('<medium') {
    margin-top: 150px;
  }

  @include media('<small') {
    margin-top: 50px;
    font-size: remSize(40);
  }

  span {
    font-weight: normal;
  }
}

.landing__description {
  opacity: 0.8;
  margin-bottom: 80px;
  font-size: remSize(24);
  line-height: 1.3;

  @include media('<small') {
    margin-bottom: 30px;
    font-size: remSize(18);
  }
}

.landing__cta {
  font-size: remSize(22);
}
