// ===========================================================
// DRINKING PROGRESS
// ===========================================================

.drinking-progress {
  margin-bottom: 20px;
  color: $c_heading;
  text-align: center;

  .drinking-progress-percentage {
    font-size: remSize(60);
    font-weight: bold;
  }

  .drinking-progress-bottle {
    position: relative;
    width: 296px;
    height: 100px;
    margin: 0 auto 15px;

    @include media('<small') {
      width: 219px;
      height: 74px;
    }

    &:before {
      display: block;
      content: ' ';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('#{$img-path}/bottle.svg') left top no-repeat;
    }
  }

  .drinking-progress-amount {
    font-size: remSize(28);
  }

  .drinking-progress-bar {
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
  }

  &.ranking-drinking-progress,
  &.team-drinking-progress,
  &.archive-drinking-progress {
    margin-bottom: 0;
    font-size: remSize(28);
    text-align: right;

    @include media('<small') {
      font-size: remSize(20);
    }

    @include media('<extra-small') {
      text-align: center;
    }

    .drinking-progress-label,
    .drinking-progress-bottle {
      display: inline-block;
      vertical-align: middle;
    }

    .drinking-progress-label {
      margin-right: 30px;

      @include media('<small') {
        margin-right: 10px;
      }
    }

    .drinking-progress-percentage,
    .drinking-progress-amount {
      font-size: remSize(28);

      @include media('<small') {
        font-size: remSize(20);
      }
    }

    .drinking-progress-bottle {
      width: 148px;
      height: 50px;
      margin-bottom: 0;

      @include media('<small') {
        width: 100px;
        height: 34px;
      }
    }
  }
}

#bottle-content-shape,
#bottle-content-shape-fill {
  fill: #fff;
}

.water-bottle-fill {
  fill: #b9ca2e;
  transition: all 0.3s;
}
