.menu {
  float: right;
  width: 65%;
  text-align: right;

  .menu-panel-touch__close {
    display: none;
  }

  @include media('<large') {
    width: 70%;
  }

  @include media('<medium') {
    display: none;
  }

  &.menu-panel-touch {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.9);

    .menu__list {
      margin-top: 60px;
    }

    .menu__item {
      display: block;
      margin: 0 0 20px 0;
      text-align: center;
    }

    .menu__link {
      font-size: 22px;
      color: #737373;
      border: 0;
      text-transform: capitalize;
    }

    .menu__item_active_yes .menu__link {
      color: #fff;
    }

    .menu__link:focus,
    .menu__link:active {
      background: transparent;
    }

    &.menu-panel-touch_state_open {
      display: block;
    }

    .menu-panel-touch__close {
      display: block;
      position: absolute;
      top: 24px;
      right: 24px;
      width: 24px;
      height: 24px;
      color: #8b8b8b;
      font-size: 24px;
      @extend %icon;
      -webkit-font-smoothing: none;

      &:before {
        @extend %icon-clear;
      }
    }
  }
}

.menu__list {
  margin: 11px 0 0;
  padding-left: 0;
}

.menu__item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;

  @include media('<large') {
    margin-right: 10px;
  }

  .menu__link {
    position: relative;
    font-size: remSize(14);
    font-weight: bold;
    display: inline-block;
    padding: 7px 0;
    text-decoration: none;
    border: 2px solid transparent;
    border-radius: 15px;
    transition:
      border-color 0.15s,
      color 0.15s,
      background 0.15s;
    color: $c_nav_link;

    &:after {
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 3px;
      content: '';
      transition: width 0.2s;
      background-color: $c_primary_3;
    }

    &:hover {
      color: $c_primary_1;
      text-decoration: none;
      background: transparent;

      &:after {
        width: 100%;
      }
    }
  }

  &.menu__item_active_yes {
    .menu__link {
      color: $c_primary_1;

      &:after {
        width: 100%;
      }

      &:hover {
        color: #262626;
        border-color: transparent;
      }
    }
  }
}

.menu-icon {
  display: none;
  position: absolute;
  top: 23px;
  right: 20px;
  width: 32px;
  height: 21px;
  margin-top: 16px;
  padding: 0;
  background: transparent;
  font-size: 21px;
  @extend %icon;
  -webkit-font-smoothing: none;

  &:before {
    @extend %icon-menu;
  }

  @include media('<medium') {
    display: block;
  }

  @include media('<small') {
    top: 24px;
  }
}
