// Modal Wrapper

.modal {
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 16px;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(74, 68, 68, 0.2);
  color: #003c6e !important;
}

.cookie-layer .modal-dialog-scrollable,
.modal-cookie-layer .modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: -webkit-calc(100% - 16px);
  max-height: calc(100% - 16px);
}

.cookie-layer .modal-dialog,
.modal-cookie-layer .modal-dialog {
  -webkit-box-shadow: 0 2px 20px 3px rgba(0, 30, 55, 0.1);
  box-shadow: 0 2px 20px 3px rgba(0, 30, 55, 0.1);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 24px auto 16px;
}

// Modal content

.cookie-layer .modal-dialog-scrollable .modal-content,
.modal-cookie-layer .modal-dialog-scrollable .modal-content {
  max-height: -webkit-calc(100vh - 16px);
  max-height: calc(100vh - 16px);
  overflow: hidden;
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  -webkit-box-shadow: 0 16px 32px 0 rgba(0, 30, 55, 0.15);
  box-shadow: 0 16px 32px 0 rgba(0, 30, 55, 0.15);
  height: 100%;
  outline: 0;
  position: relative;
}

@media (min-width: 992px) {
  .modal-dialog-lg,
  .modal-dialog-md {
  }
}

@media (min-width: 768px) {
  .modal-dialog-lg,
  .modal-dialog-md {
    min-width: 300px;
  }
}

.modal-dialog-md {
  max-width: 900px;
}

.modal-dialog-md,
.modal-dialog-sm {
  margin: 24px auto 16px;
  width: 100%;
}

// Modal body

.modal-body {
  padding: 24px 16px 16px;

  @media (min-width: 768px) {
    padding: 16px 24px 32px;
  }

  h2 {
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.333;
    padding-bottom: 24px;
    margin: 0;

    @media (min-width: 480px) {
      font-size: 1.375em;
      line-height: 1.273;
    }
  }

  p {
    margin: 0;
  }
}

.cookie-layer .modal-body,
.modal-cookie-layer .modal-body {
  padding: 24px 16px 10px;
}

// Modal footer

.modal-footer {
  background: #fff;
  display: inline-block;
  padding: 0 16px 16px;
  width: 100%;

  @media (min-width: 768px) {
    display: inline-block;
    margin-bottom: -7px;
    padding: 0;
  }

  .btn {
    display: block;
    border-bottom-style: solid;
    border-radius: 0;
    border-width: 0 0 3px;
    position: relative;
    height: 48px;
    width: 100%;
    padding: 2px 24px 0;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-color: #003c6e;
    border-bottom-color: #003c6e;
    color: #fff;
    line-height: 1.5;
    font-weight: 600;

    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: #003c6e;
    }

    &:focus {
      background-color: #fff;
      border-color: #fff;
      outline: none;
      color: #003c6e;
    }
  }
}

.cookie-layer .cookie-layer-button,
.modal-cookie-layer .cookie-layer-button {
  overflow: auto;
  margin-bottom: -9px;
  padding: 10px 0 0;
}
