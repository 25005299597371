// ===========================================================
// DASHBOARD GENERAL
// ===========================================================

.dashboard {
  display: flex;
  flex-wrap: wrap;
  max-width: #{$w_container}px;
  margin: 0 auto;

  @include media('<small') {
    display: block;
  }

  .title_type_medium {
    text-shadow: 1px 1px 1px #eff3f6;
  }

  .box {
    margin-bottom: 100px;

    @include media('<small') {
      margin-bottom: 30px;
    }
  }
}

.dashboard__button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  padding-top: 12px;
  text-align: center;
  box-sizing: border-box;
}

.dashboard__img {
  display: inline-block;
  margin-bottom: 23px;
}

.dashboard__text {
  font-weight: 300;
  font-size: 16px;
  margin: 0 0 15px 0;
  padding: 0 30px;
  line-height: 28px;

  & + .dashboard__text {
    margin-top: 40px;
  }
}

.dashboard__text_margin_top {
  margin-top: 32px;
}

.dashboard__line {
  padding: 12px 30px 12px 34px;
  background: #f7f7f7;
  vertical-align: middle;
}

.dashboard__line-number {
  font-size: 36px;
  line-height: 36px;

  span {
    display: block;
    font-size: 14px;
    color: #979797;
    font-style: italic;
  }
}

.dashboard__line-text {
  max-width: 75%;

  &:first-line {
    line-height: 36px;
  }
}

.dashboard__item-title {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 18px;
}

.dashboard__title {
  font-weight: bold;
  color: #262626;
  margin-top: 36px;
  text-transform: uppercase;
}

// ===========================================================
// DASHBOARD BOXES
// ===========================================================

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #262626;
  box-sizing: border-box;
  text-decoration: none;

  @include media('<small') {
    display: block;
  }

  &:hover {
    text-decoration: none;
    color: #262626;
  }

  &.box_1 {
    width: 100%;
    padding: 0 20px;
  }

  &.box_2 {
    width: 50%;
    padding: 0 20px;

    @include media('<medium') {
      width: 100%;
    }
  }

  .title_type_medium {
    margin: 0 0 25px 0;
    line-height: 1.6;
  }

  p {
    margin-bottom: 35px;
  }

  .form-group {
    margin-bottom: 40px;
  }

  .input-group.date,
  .form-control {
    max-width: 304px;
  }
}

a.box {
  cursor: pointer;
}

.box__title-wrap .title_type_medium {
  display: inline-block;
  margin-right: 25px;
}

.box__wrap {
  display: flex;
  -webkit-flex: 1 auto;
  -ms-flex: 1 auto;
  flex: 1 auto;
  flex-direction: column;
  background: #f2f5f8;

  .box__title {
    margin: 25px 0 38px;
    padding-left: 28px;
  }

  img {
    max-width: 100%;
  }

  &:before,
  &:after {
    display: block;
    opacity: 0;
    content: ' ';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
  }

  &:before {
    background: rgba(240, 240, 240, 0.7);
  }

  &:after {
    background: url('#{$img-path}/loading.gif') center no-repeat;
  }

  .box.is-loading & {
    position: relative;

    &:before {
      opacity: 1;
      z-index: 99;
    }

    &:after {
      opacity: 1;
      z-index: 100;
    }
  }
}

.box__wrap_padding {
  padding: 25px 30px 60px 30px;

  @include media('<small') {
    padding: 15px 20px 30px;
  }
}

.box__wrap_type_dashboard {
  padding-bottom: 108px;
}

.box__wrap_bg_gray {
  background: #f7f7f7;
}

.box_media {
  width: 100%;
  overflow: hidden;
}

.box_media_padding {
  padding: 10px 0;
}

.box__img {
  display: block;
  width: 100%;
  border: 0;
}

.box__text {
  margin-top: 0;
  margin-bottom: 27px;

  & + .box__text {
    margin-top: 35px;
  }
}

.box__button {
  text-align: center;
}

.box__hint {
  font-weight: 300;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 30px;
  color: #939393;
}

.box__link {
  display: inline-block;
}

.box-image-wrap {
  display: flex;
  position: relative;
  width: 100%;
  flex-grow: 1;
  text-align: center;

  .box-image {
    width: 100%;
    flex-grow: 1;
    align-self: center;
  }
}

// Water quantity

.input-group-water-qty {
  width: 100%;

  .radioWr {
    display: table-cell;
    padding-top: 175px;
    text-align: center;

    label {
      display: inline-block;
      position: relative;
    }
  }

  @include media('<large') {
    .radioWr label {
      padding-left: 30px;
    }
  }

  @include media('<extra-small') {
    .radioWr {
      display: block;
      text-align: left;
      margin-bottom: 25px;
      padding-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon-water-qty {
      display: none;
    }
  }
}

.icon-water-qty {
  .icon {
    display: block;
    position: absolute;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s;
  }

  .path1,
  .path2,
  .path3,
  .path4 {
    position: absolute;
    bottom: 0;
    left: 0;

    &:before {
      position: relative;
    }
  }

  .icon-water-qty-200,
  .icon-water-qty-500 {
    opacity: 0.5;

    .path1 {
      &:before {
        z-index: 3;
      }
    }

    .path2 {
      &:before {
        z-index: 1;
      }
    }

    .path3 {
      height: 0;
      overflow: hidden;
      transition:
        height 0.3s ease,
        bottom 0s 0.3s;

      &:before {
        z-index: 2;
      }
    }
  }

  .icon-water-qty-1000,
  .icon-water-qty-1500 {
    opacity: 0.5;

    .path1 {
      &:before {
        z-index: 3;
      }
    }

    .path2 {
      &:before {
        z-index: 3;
      }
    }

    .path3 {
      &:before {
        z-index: 1;
      }
    }

    .path4 {
      height: 0;
      overflow: hidden;
      transition:
        height 0.3s ease,
        bottom 0s 0.3s;

      &:before {
        z-index: 2;
      }
    }
  }

  .icon-water-qty-200 {
    width: 42px;
    height: 51px;
    font-size: 51px;
  }

  .icon-water-qty-500 {
    width: 44px;
    height: 60px;
    font-size: 60px;
  }

  .icon-water-qty-1000 {
    width: 50px;
    height: 110px;
    font-size: 110px;
  }

  .icon-water-qty-1500 {
    width: 60px;
    height: 122px;
    font-size: 122px;
  }
}

input[type='radio'] {
  &:checked {
    & + label {
      .icon-water-qty-200,
      .icon-water-qty-500 {
        opacity: 1;

        .path3 {
          height: 100%;
        }
      }

      .icon-water-qty-1000,
      .icon-water-qty-1500 {
        opacity: 1;

        .path4 {
          height: 100%;
        }
      }
    }
  }
}

.warning-drinking-too-much {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    max-height: 999px;
    overflow: visible;
  }
}
