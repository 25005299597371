.tabs-menu {
  padding: 0;
  margin-bottom: 38px;
}

.tabs-menu__wrap {
  margin: 0;
  padding: 0 0 40px 0;
  list-style: none;
  white-space: nowrap;
  overflow: auto;
}

.tabs-menu__item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-left: 0;
  padding: 0;
  color: $c_dgrey_2;

  &:first-child {
    margin-left: 0;
  }

  .tabs-menu__link {
    display: block;
    padding: 18px 10px;
    border-bottom: 2px solid $c_lgrey_3;
    color: $c_dgrey_2;
    font-family: $f_regular;
    font-size: remSize(15);
    font-weight: bold;
    text-decoration: none;
    transition: all 0.2s;

    &:hover,
    &:active {
      border-bottom-color: $c_primary_3;
      color: $c_primary_3;
      text-decoration: none;
    }
  }

  &.tabs-menu__item_selected {
    .tabs-menu__link {
      color: $c_primary_3;
      border-bottom-color: $c_primary_3;
    }
  }
}
